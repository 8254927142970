.chat{
  position: fixed;
  right: 50px;
  bottom: 1%;
  background-color: transparent;
  color: rgb(100,234,255);
  border-radius: 20px;
  font-family: Outfit;
}
.chat-header-container {
  margin-left: 20px;
  display: flex;
  align-items: center;
  gap: 10px; 
}

.chat-pill {
  padding: 6px 16px;
  background: linear-gradient(349deg, rgba(104,40,162,1) 30%, rgba(119,205,221,1) 85%);
  color: white;
  border-radius: 25px; 
}

.studio-title {
  color: white; 
  font-weight: lighter;
  font-size: 30px;
}

.chat-body {
  width: 100%;
  display: block;
  position: relative;
  padding: 1.3rem;
  max-width: 800px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(7, 10, 56, 0.25);
  border-radius: 25px;
}

.chat-bubble-image,
.user-online-image{
  background: rgb(104,40,162);
  background: linear-gradient(349deg, rgba(104,40,162,1) 30%, rgba(119,205,221,1) 85%);
}

.image-container {
  position: relative;
  right: 15px;
  width: 65px; 
  height: 65px; 
  border-radius: 50%;
}

.image-container::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  padding: 0.5px; 
  background: conic-gradient(var(--gradient-color, purple), transparent);
  -webkit-mask: 
    radial-gradient(farthest-side, transparent calc(100% - 4px), white calc(100% - 4px));
  mask: 
    radial-gradient(farthest-side, transparent calc(100% - 4px), white calc(100% - 4px));
  transform: rotate(180deg); 
  pointer-events: none; 
}

.image-inner-container {
  position: relative;
  top: 6px; 
  left: 6px; 
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  border-radius: 50%;
  overflow: hidden;
}

.chat-bubble-image {
  width: 100%; 
  height: auto; 
  object-fit: cover;
  object-position: center top;
  transform: scale(2.2) translateY(20%); 
}

.outer-image-container{
  margin-bottom: 0;
  margin-top: 0;
}

.image-container-online{
  position: relative;
  display: inline-block;
  width: 42px; 
  height: 42px; 
  margin: 2px;
}

.users-online-inner-image-container{
  position: absolute;
  top: 3px;
  left: 2px; 
  width: calc(100% -6px);
  height: calc(100% - 6px);
  border-radius: 50%;
  overflow: hidden;
  border: 2px var(--gradient-color) solid;
}
.user-online-image{
  display: block;
  width: 100%; 
  height: auto; 
  object-fit: cover; 
  object-position: center top; 
  transform: scale(2.2) translateY(20%); 
}

.soledad{
  transform: scale(1.9) translateY(12%);
}
.fbx{
  transform: scale(1.2) translateY(9%);
}
.chat-body::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 25px;
  border: 2px solid transparent;
  background: linear-gradient(65deg,rgb(98, 233, 29),rgb(250, 236, 42), rgb(234, 72, 89)  , rgb(114, 72, 175) 40%,rgb(25, 72, 240) 60%) border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  pointer-events: none;
  z-index: 1; 
}

.chatText{
  color: rgb(100,234,255);
  text-align: center;
  font-family: Outfit;
}

.chat-container{
  width: 100%;
  overflow-y: scroll;
  text-align: left;
  height: 30vh;
  margin-bottom: 20px;
}

.chat-bubble-container{
  position: relative;
  right: 20px;
}

.chat-bubble{
  position: relative;
  left: 80px;
  top: -75px;
  text-decoration: none;
  text-align: left;
  width: 80%;
  margin-right: auto;
  border-radius: 5px;
  color: white;
  word-wrap: break-word; 
  white-space: normal;
}

.bubble-timestamp{
  margin-right: auto;
  color: white;
}

.user-chat-bubble{
  background-color:#baffc9;
  width: 80%;
  color: black;
  text-decoration: none;
  margin-right: auto;
  border-radius: 5px;
  word-wrap: break-word; 
  white-space: normal;
}
.user-bubble-timestamp{
  color: white;
  position: relative;
}

.chat-input{
  display: flex;
  align-items: center;
  margin: 1% 0 1% 5%;
  margin-top: 4%;
  width: 70%;
  z-index: 25000 !important;
  padding:0.2rem 0.2rem 0.2rem 1.2rem;
  border: double 1px transparent;
  border-radius: 50px;
  background-image: linear-gradient(black , black ), 
  linear-gradient(90deg, #61479e 0%, #1d4e76 35%, #fbfbfb 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-color: rgba(0,19,53,1);
  position: relative;
  z-index: 2;
}

#chat-input{
  border: none;
  background-color: transparent;
  width: 90%;
  height: 100%;
  color: white;
  font-size: larger;
}

#chat-input:focus {
  outline: none; 
}

.emoji-button {
  margin-left: 5px; 
  font-size: 240%;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  color: lightslategray;
}

.emoji-picker{
  position: absolute;
  bottom: 130%;
  right: 10%;
}

.chat-container::-webkit-scrollbar {
  display: none;
}

.chat-container {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.send-button{
  background-color: transparent;
  border: none;
  color: #61dafb;
  border-radius: 50%;
  padding: 0;
}

.is-typing-container{
  position: absolute;
  font-size: 14px;
  color: lightgray;
  bottom: 88px;
  left: 68px;
  margin-left: 52px;
  margin-top: 20px;
}

.dot-text-chat{
  position: absolute;
  left: 45px;
  top: 30px;
  font-size: 200%;
  margin: 0;
  padding: 0;;
}
.red-text-chat{
  color: red;
}
.green-text-chat{
  color: lime;
}

/* Wobble Animation */
@keyframes wobble {
  0%, 100% {
    transform: translateX(0);
  }
  15%, 45%, 75% {
    transform: translateX(-1px);
  }
  30%, 60%, 90% {
    transform: translateX(1px);
  }
}

.wobble {
  animation: wobble 0.5s ease-in-out;
}

.scroll-to-bottom {
  position: fixed; 
  bottom: 90px;
  right: 40%;
  background-color: transparent;
  border-radius: 25px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  color: white;
  border: 2px solid transparent;
  cursor: pointer;
  z-index: 1000;
}


.scroll-to-bottom:hover {
  background-color: rgba(3, 5, 29, 0.25);
  color: white;
}
