body {
  overflow: hidden;
  font-family: Outfit;
  background-color: black;
}

.app{
  margin: 0;
  padding: 0;
  background-image: url('images/background.jpg'); 
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat;
  position: fixed; 
  height: 100vh;
  width: 100vw;
}

.Landing {
  text-align: center;
  height: 100vh;
  background-color: black;
  overflow: hidden;
}

model-viewer {
  width: 100vw;
  height: 100vh;
  display: block;
}

.view-button {
  background: #fff;
  border-radius: 4px;
  border: none;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  color: rgba(0, 0, 0, 0.8);
  display: block;
  font-family: Futura, Helvetica Neue, sans-serif;
  font-size: 12px;
  font-weight: 700;
  max-width: 128px;
  overflow-wrap: break-word;
  padding: 0.5em 1em;
  position: absolute;
  width: max-content;
  height: max-content;
  transform: translate3d(-50%, -50%, 0);
}

.Hotspot {
  background: #fff;
  border-radius: 32px;
  border: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  cursor: pointer;
  height: 24px;
  padding: 8px;
  position: relative;
  transition: opacity 0.3s;
  width: 24px;
}


.Hotspot:focus {
  border: 4px solid rgb(0, 128, 200);
  height: 32px;
  outline: none;
  width: 32px;
}

.HotspotAnnotation{
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  color: rgba(0, 0, 0, 0.8);
  display: block;
  font-family: Futura, Helvetica Neue, sans-serif;
  font-size: 18px;
  font-weight: 700;
  left: calc(100% + 1em);
  max-width: 128px;
  overflow-wrap: break-word;
  padding: 0.5em 1em;
  position: absolute;
  top: 50%;
  width: max-content;
}

.Hotspot:not([data-visible]) > * {
  opacity: 0;
  pointer-events: none;
  transform: translateY(calc(-50% + 4px));
  transition: transform 0.3s, opacity 0.3s;
}


.ticketPage{
  margin: 0;
  padding: 0;
  background-image: url('images/background.jpg'); 
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat;
  position: fixed; 
  height: 100vh;
  width: 100vw;
}

.navBar{
  background: rgb(19,91,156);
  background: linear-gradient(90deg, rgba(19,91,156,1) 37%, rgba(121,191,215,1) 81%);

}
.css-19kzrtu{
  padding: 0 !important;
}

.logo-logged-in{
  display: flex;
  align-items: center;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
  margin-top: 2%;
}

a{
  text-decoration: none;
  color: white;
}

img {
  max-width: 100%; 
  max-height: 100%; 
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.header{
  text-align: center;
}

.card{
  margin: 5%;
  text-align: center;
  padding: 2%;
  border: #282c34 1px solid;
  box-shadow: 5px 5px 0px 0px black ;
  background-color: lightgray;
  font-size: large;
  border-radius: 25px;
}

.loading-text {
  color: #61dafb;
  font-size: 20px;
  font-weight: bold;
  position: relative;
  top: 30%;
  left: 45%;
}

.dots::after {
  content: '';
  animation: dots 1.5s steps(4, end) infinite;
}

@keyframes dots {
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
  100% {
    content: '';
  }
}

.centre{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}

.unity{
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: white;
  cursor: default !important;
}

.controlBtns{
  position: absolute;
  bottom:1%;
  right: 4%;
  z-index: 250000;
}

.unityBtn {
  padding: 15%;
  background-color: rgba(34, 36, 48, 0.7);
  color: rgb(100, 234, 255);
  border-radius: 50%;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 250000;
  border: none;
  transition: background 0.3s ease-in-out;
}
.logoBtn{
  padding: 15%;
  color: rgb(100, 234, 255);
  background-color: transparent;
  border-radius: 50%;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 250000;
  border: none;
  transition: background 0.3s ease-in-out;
}

.unityBtn:hover {
  background: linear-gradient(171deg, rgb(34, 110, 246) 27%, rgba(40, 179, 238, 1) 61%);
}

.unityBtn img {
  transition: all 0.3s ease-in-out;
}

#profileBtn:hover{
  background-color: rgb(100,234,255) !important;
  color: rgb(34, 36, 48) !important;
}

ul{
  list-style-type: none;
}
.chatText{
  color: rgb(100,234,255);
  text-align: center;
  font-family: Outfit;
}
#outlined-basic{
  color: white;
}
.css-1x5jdmq{
  color: white !important;
}

#name-input{
  color: black !important;
}

.avatar-container{
  padding-top: 300px;  
  display: flex;
}

.avatar-card{
  background-color: #bae1ff;
  padding: 100px;
  width: 30%;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 1;
}
.footer{
  position: absolute;
  bottom: 0;
  width: 100%;
}

.centered-div{
  position: absolute;
  top: 50%;
  left: 50%;
}


.circle{
  border-radius: 50%;
}

.dotted{
  border: dotted rgb(100,234,255) 1px;
  box-shadow: #61dafb 10px;
  border-radius: 50%;
  pointer-events: none;
}

.absolute{
  position: absolute;
  top: 50%;
  left: 50%;
}

.offset{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) 
}

.spring-div{
  border: dotted rgb(100,234,255) 10px;
  box-shadow: #61dafb 10px;
  position: absolute;
  top: 40%;
  left: 40%;
  border-radius: 50%;
  pointer-events: none;
}

.circle1{
  transform: translate(-50%, -50%)
}
.shape1{
  position: relative;
  left: 50.8%;
  top: 38%;
  cursor: pointer;
}
.shape2{
  position: relative;
  left: -50.5%;
  top: 43%;
  z-index: 2000;
  cursor: pointer;
}
.shape3{
  position: relative;
  left: 50.3%;
  top: 45%;
  z-index: 2000;
  cursor: pointer;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center; 
  width: 100%;
  height: 100vh;
}

.centre-image {
  position: fixed;
  top: -3%;
  pointer-events: none;
}


.input-container {
  position: absolute;
  top: 75%; 
  left: 0; 
  width: 100%;
  height: auto; 
  display: flex;
  justify-content: center; 
  align-items: center; 
  z-index: 1; 
}


.button {
  width: 15%; 
  position: absolute;
  left: 50%;
  top: 73%; 
  transform: translate(-50%, -50%);
}


@keyframes circle-closkwise-spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes circle-anticlockwise-spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .circle2{
    animation: circle-anticlockwise-spin infinite 30s linear;
  }
  .circle3{
    animation: circle-closkwise-spin infinite 25s linear;
  }
  .dot1{
    animation: circle-anticlockwise-spin infinite 90s linear;
  }
  .dot2{
    animation: circle-closkwise-spin infinite 75s linear;
  }
}

.avatar-background{
  background: rgb(6,46,170);
  background: linear-gradient(0deg, rgba(6,46,170,1) 5%, rgba(0,19,53,1) 35%);
  height: 100vh;
  width: 100vw;
}

.avatar-logo{
  position: relative;
  left: 30%;
  top: 20%;
}

.avatar-input-area1 {
  z-index: 10000;
  color: white;
  font-size: 2.2vh;
  position: absolute;
  left: 50%; /* Center horizontally */
  top: 50%;  /* Center vertically */
  transform: translate(-50%, -50%); /* Adjust for element's own size */
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: center;
  align-items: center;
}

.avatar-square1{
  z-index: 10000;
  position: absolute;
  top: 53%;
  left: 117%;
  width: 8%;
  height: 8%;
}

.avatar-input-area2{
  text-align: center;
  z-index: 10000;
  color: white;
  font-size: 2.2vh;
  position: absolute;
  left: 30%;
  top: 58%;
}

.avatar-square2{
  z-index: 1500000;
  position: absolute;
  top: -5%;
  left: 97%;
  width: 8%;
  height: 8%;
  transform: rotate(-45deg);
}

.custom-input {
  border: double 2px transparent;
  border-radius: 50px;
  background-image: linear-gradient(rgba(0,19,53,1), rgba(0,19,53,1)), 
  linear-gradient(25deg, rgba(34,80,229,1) 0%, rgba(205,224,250,1) 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-color: rgba(0,19,53,1);
  color: white;
  position: relative;
  right: 3%;
}

.custom-input::placeholder {
  color: rgb(66, 105, 233);
}

.customButton{
  text-align: center;
  width: 50%;
  padding: 3%;
  font-size: 85%;
  color: white;
  border-radius: 10px;
  background: linear-gradient(220deg, rgb(7, 93, 243) 27%, rgba(40, 179, 238, 1) 61%);
  border: none;
  cursor: pointer;
  z-index: 25000000;
  transition: background 0.3s, opacity 0.3s;
}
.customButton:active {
  background: linear-gradient(200deg, rgba(34, 110, 246, 0.8) 27%, rgba(40, 179, 238, 0.8) 61%);
  transform: translateY(2px); /* Simulates pressing down */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Adds a pressed shadow effect */
}
.customButton:disabled{
  background: linear-gradient(200deg, rgba(150, 150, 150, 0.7) 27%, rgba(200, 200, 200, 0.7) 61%);
  cursor: not-allowed;
  opacity: 0.6;
  color: rgba(255, 255, 255, 0.6); 
}
.avatar-btn::before{
  content: "";
  position: absolute;
  left: 1px;
  right: 1px;
  top: 1px;
  bottom: 1px;
  border-radius: 25px;
  background-color: #222430;
  z-index: -1;
}
.avatar-btn::after{
  color: white;
}
.avatar-btn{
  padding: 20px 90px;
  border: none;
  outline: none;
  position: relative;
  border-radius: 25px;
  background: linear-gradient(45deg, rgba(34,80,229,1) 0%, rgba(205,224,250,1) 100%);
  cursor: pointer;
  z-index: 1;
  color: white;
  margin: 15px;
  font-size: large;
}
.avatar-btn:active {
  background: linear-gradient(200deg, rgba(34, 110, 246, 0.8) 27%, rgba(40, 179, 238, 0.8) 61%);
  transform: translateY(2px); 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); 
}

.selected-default{
  background: linear-gradient(65deg,rgb(98, 233, 29),rgb(250, 236, 42), rgb(234, 72, 89)  , rgb(114, 72, 175) 40%,rgb(25, 72, 240) 60%);
}
.avatar-display{
  height: 100vh;
  width: 100vw;
  position: absolute;
  bottom: 0;
  left: -18%;
}

.send-button{
  color: #61dafb;
  border: none;
  background-color: transparent;
}

#btn-click-image{
  background-image: url("/public/images/buttons/click-icon@2x.png");
  background-size: stretch;
  background-repeat: no-repeat;
  height: 80px;
  width: 80px;
  float: left;
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

.green {
  background-color: #adff5f !important;
}
.red {
  background-color: #ff689d !important;
}

.profile-modal::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 25px;
  border: 2px solid transparent;
  background: linear-gradient(65deg,rgb(98, 233, 29),rgb(250, 236, 42), rgb(234, 72, 89)  , rgb(114, 72, 175) 40%,rgb(25, 72, 240) 60%) border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  pointer-events: none;
  z-index: 1;
}
.circle::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50%;
  border:4px solid transparent;
  background: linear-gradient(45deg,rgb(98, 233, 29) 15%,rgb(250, 236, 42), rgb(234, 72, 89)  , rgb(114, 72, 175) 40%,rgb(25, 72, 240) 60%) border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  pointer-events: none;
  z-index: 1;
}

.profile-modal{
  border-radius: 25px;
  background-color: rgba(34, 36, 48, 0.7);
  height: 60%;
}
@keyframes glow {
  0% {
    box-shadow: 0 0 5px rgb(100, 234, 255), 0 0 10px rgb(100, 234, 255), 0 0 15px rgb(100, 234, 255), 0 0 20px rgb(100, 234, 255),
                inset 0 0 2px rgb(100, 234, 255), inset 0 0 4px rgb(100, 234, 255), inset 0 0 6px rgb(100, 234, 255), inset 0 0 8px rgb(100, 234, 255);
  }
  50% {
    box-shadow: 0 0 20px rgb(100, 234, 255), 0 0 30px rgb(100, 234, 255), 0 0 40px rgb(100, 234, 255), 0 0 50px rgb(100, 234, 255),
                inset 0 0 10px rgb(100, 234, 255), inset 0 0 15px rgb(100, 234, 255), inset 0 0 20px rgb(100, 234, 255), inset 0 0 25px rgb(100, 234, 255);
  }
  100% {
    box-shadow: 0 0 0px rgb(100, 234, 255), 0 0 0px rgb(100, 234, 255), 0 0 0px rgb(100, 234, 255), 0 0 0px rgb(100, 234, 255),
                inset 0 0 0px rgb(100, 234, 255), inset 0 0 0px rgb(100, 234, 255), inset 0 0 0px rgb(100, 234, 255), inset 0 0 0px rgb(100, 234, 255);
  }
}
.prompt {
  animation: glow 2s forwards;
}

.prompt::before {
  content: '';
  position: absolute;
  top: -10px;
  bottom: -10px;
  left: -10px;
  right: -10px;
  opacity: 0.5;
  z-index: -1;
}
.close-button-rpm{
  padding: 0;
  font-weight: bolder;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.chat-background{
  background: rgb(0,19,53);
  background: linear-gradient(360deg, rgba(0,19,53,0.7469362745098039) 20%, rgba(38,44,63,0) 100%);
  height: 500px;
  width: 1000px;
  position: absolute;
  z-index: 1;
}
.css-1rc141i {
  z-index: 5;
}

/* PATCH PROVIDED BY ANDREW BELOW: */
#btnShowCallAudioSettings:hover,
#btnStartCall:hover,
#btnAcceptCall:hover,
#btnRejectCall:hover,
#btnEndCall:hover {
  background: linear-gradient(171deg, rgb(34, 110, 246) 27%, rgba(40, 179, 238, 1) 61%) ;
  color: #222430 !important;
}

#btnShowCallAudioSettings svg {
  height: 32px ;
  transition: all 0.3s ease-in-out ;
  width: 32px ;
}
#ctnCallAudioSettings > div {
  max-width: 1024px;
}

#ctnCallAudioSettings > div::before,
#cntCallInfo::before,
#lblIncomingCallInfo::before {
  background: linear-gradient(65deg,rgb(98, 233, 29),rgb(250, 236, 42), rgb(234, 72, 89)  , rgb(114, 72, 175) 40%,rgb(25, 72, 240) 60%) border-box;
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask-composite: exclude; border-radius: 32px;
  content: "";
  inset: 0;
  padding: 2px;
  pointer-events: none;
  position: absolute;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
}

#ctnCallAudioSettings select {
  width: 100%;
}

#cntCallInfo table {
  width: 100%;
}

