
.debug-panel::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 25px;
    border: 2px solid transparent;
    background: linear-gradient(65deg,rgb(98, 233, 29),rgb(250, 236, 42), rgb(234, 72, 89)  , rgb(114, 72, 175) 40%,rgb(25, 72, 240) 60%) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    pointer-events: none;
    z-index: 1; 
}
.debug-panel{
    position: absolute;
    top: 65%;
    left: 10%;
    color: white;
    height: 300px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(7, 10, 56, 0.25);
    border-radius: 25px;
}
#debug-heading{
    position: relative;
    top: 40px;
    left: 100px;
    margin: 0;
    padding: 0;
    font-family: Outfit;
    font-weight: normal;
}
#debug-close{
    position: relative;
    top: -25px;
    left: 550px;
    cursor: pointer;
}
#devices{
    position: relative;
    top: 30px;
    left: 40px;
    font-size: small;
    overflow-y: scroll;
    width: 89%;
    height: 45%;
}
#devices::-webkit-scrollbar {
    width: 5px; 
  }
  
#devices::-webkit-scrollbar-track {
    background-color: transparent; 
}
  
#devices::-webkit-scrollbar-thumb {
    background-color: #61dafb;
    border-radius: 5px; 
}
  
#devices::-webkit-scrollbar-thumb:hover {
    background-color: #5ab3e2; 
}

#devices tr > td:nth-child(1) {
    width: 160px;
}

#devices tr > td:nth-child(2) {
    padding-left: 50px;
    width: 370px;
}
.debug-btn{
    padding: 0;
    border: none;
    background-color: transparent;
    margin: 0;
    color: white;
    cursor: pointer;
}
#debug-send-btn{
    position:relative;
    top: -200px;
    left: 450px;
    width: 110px;
    height: 50px;
    background-image: url("/public/images/buttons/send-button@4x.png");
    background-size: cover; 
    background-repeat: no-repeat; 
    border: none;
    cursor: pointer;
    background-color: transparent; 
}
